import { initializeApp } from 'firebase/app';
import { getAuth } from "firebase/auth";
import { getFirestore, getDocs, collection } from "firebase/firestore";
import { getDownloadURL, getStorage, ref } from "firebase/storage";

require('firebase/auth');
require('firebase/firestore');


const firebaseConfig = {
  apiKey: "AIzaSyBhGRim4pDGjP4MkZf1zBFV6PAl-W4EF0c",
  authDomain: "portfolio-guillaume-vezina.firebaseapp.com",
  projectId: "portfolio-guillaume-vezina",
  storageBucket: "portfolio-guillaume-vezina.appspot.com",
  messagingSenderId: "211373037442",
  appId: "1:211373037442:web:b53c79584aac175ab1219b",
  measurementId: "G-E6DCKVBRSV",
};



const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);

const storage = getStorage();
const avatarRef = ref(storage, 'avatars/Profil-001.jpg');




getDownloadURL(avatarRef)
  .then((url) =>
  {
    const xhr = new XMLHttpRequest();
    xhr.responseType = 'blob';
    xhr.onload = (event) =>
    {
      // const blob = xhr.response;
    };

    xhr.open('GET', url);
    xhr.send();
  });



  // Define an array of collection names
const collectionNames = ['avatars', 'liens-sociaux', 'logos', 'projets', 'textes'];

// Function to retrieve data from Firestore and cache it
async function fetchAndCacheDataForCollections(collectionNames) {
  const cachedData = {};

  for (const collectionName of collectionNames) {
    // Check if data for the collection exists in local cache
    const cachedDataJSON = localStorage.getItem(collectionName);

    if (cachedDataJSON) {
      // If data is present in the cache, retrieve it from the cache
      cachedData[collectionName] = JSON.parse(cachedDataJSON);
    } else {
      // If data is not in the cache, fetch it from Firestore
      const querySnapshot = await getDocs(collection(db, collectionName));
      const data = [];

      querySnapshot.forEach((doc) => {
        data.push(doc.data());
      });

      // Store the data in local cache
      localStorage.setItem(collectionName, JSON.stringify(data));

      cachedData[collectionName] = data;
    }
  }

  return cachedData;
}

// Example of usage
fetchAndCacheDataForCollections(collectionNames)
  .then((cachedData) => {
    // Use the retrieved data for each collection
    /*
    for (const collectionName in cachedData) {
      console.log(`Retrieved data for ${collectionName}:`, cachedData[collectionName]);
    }
    */
  })
  .catch((error) => {
    console.error('Error fetching data:', error);
  });


export { db, auth }